import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Helmet from 'react-helmet';

import styled from 'styled-components';

import { bubbleSvg } from '../helpers';
import { lightTeal } from '../colors';
import '../globalStyles.css';
import Layout from '../components/Layout';

const OnboardingContainer = styled.div`
  max-width: 1920px;
  padding: 0 100px;
  padding-bottom: 10%;
  color: var(--color-dark-grey);
  
  // background-image: ${bubbleSvg(lightTeal, -8)};
  // background-size: 200%;
  // background-repeat: no-repeat;
  // background-position: 20% 20%;

  & a {
    color: var(--color-dark-grey);
    text-decoration: none;
    transition: all ease 0.3s;
    background-size: auto 175%;
    box-shadow: 0 1px 0 0 rgba(52, 191, 201, 0.6),
      0 4px 0 -3px rgba(52, 191, 201, 0.6),
      inset 0 0 0 0 rgba(52, 191, 201, 0.6);
  }
  
  & a:hover {
    background-position-y: 105%;
    color: var(--color-white);
    box-shadow: 0 1px 0 0 rgba(52, 191, 201, 0.8),
      0 0px 0 2px rgba(52, 191, 201, 0.8),
      inset 0 -1.5em 0 0 rgba(52, 191, 201, 0.8);
  }

  @media (min-width: 1921px) {
    margin: auto;
  }

  @media (max-width: 1250px) {
    padding: 0 50px;
    padding-bottom: 10%;
  }

  @media (max-width: 550px) {
    padding: 0;
    padding-bottom: 10%;
  }
`;

const OnboardingHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1023px) {
    flex-direction: column-reverse;
  }
`;

const Headline = styled.h1`
  font-size: 60px;
  color: var(--color-teal);
  white-space: pre-wrap;
  margin-bottom: 2%;
  margin-top: 0;
  padding-top: 5%;

  @media (max-width: 1450px) {
    font-size: 55px;
  }

  @media (max-width: 1250px) {
    font-size: 44px;
  }

  @media (max-width: 1023px) {
    text-align: center;
    margin-bottom: 4%;
  }

  @media (max-width: 900px) {
    font-size: 40px;
  }

  @media (max-width: 750px) {
    font-size: 37px;
  }

  @media (max-width: 550px) {
    font-size: 29px;
    width: 95%;
    margin: auto;
    padding-top: 5%;
    padding-bottom: 4%;
  }
`;

const TextSectionHeader = styled.div`
  width: 40%;
 
  & p {
    line-height: 130%;
  }

  @media (max-width: 1250px) {
    width: 45%;
  }

  @media (max-width: 1023px) {
    width: 100%;
  }
  
`;

const Subtext = styled.h3`
  white-space: pre-wrap;
  font-size: 17px;
  margin-top: 3%;

  & p {
    margin-top: 0;
  }

  @media (max-width: 1250px) {
    font-size: 16px;
  }
  
  @media (max-width: 1023px) {
    margin-top: 8%;
  }

  @media (max-width: 550px) {
    font-size: 15px;
    width: 95%;
    margin: auto;
    padding-top: 6%;
    white-space: normal;
  }

`;

const HeaderText = styled.div`
  white-space: pre-wrap;
  font-size: 17px;
  margin-top: 6%;;

  & p {
    margin-top: 0;
  }

  @media (max-width: 1250px) {
    font-size: 16px;
  }

  @media (max-width: 1023px) {
    margin-top: 0;
  }

  @media (max-width: 550px) {
    font-size: 15px;
    width: 95%;
    margin: auto;
    white-space: normal;
  }
`;

const HeaderImage = styled.img`
  width: 40%;
  height: 100%;

  @media (max-width: 1500px) {
    width: 50%;
  }

  @media (max-width: 1023px) {
    width: 65%;
    display: flex;
    margin: auto;
  }

  @media (max-width: 550px) {
    width: 95%;
  }
`;

const OnboardingOverviewContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 10%;
  padding-bottom: 10%;

  @media (max-width: 850px) {
    flex-wrap: wrap;
  }

  @media (max-width: 550px) {
    width: 95%;
    margin: auto;
  }

  & div {
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @media (max-width: 850px) {
      flex: 30%;
    }

    @media (max-width: 550px) {
      flex: 50%;
    }
  }
`;

const OnboardingTopic = styled.div`

  & p {
    padding-top: 5%;

    @media (max-width: 1023px) {
      font-size: 15px;
    }

    @media (max-width: 850px) {
      padding-left: 20px;
      padding-right: 20px;
    }

    @media (max-width: 600px) {
      font-size: 14px;
    }
  }
`;

const Icon = styled.img`
  width: 50%;
  max-width: 150px;

  @media (max-width: 1023px) {
    width: 100px;
  }

  @media (max-width: 600px) {
    width: 75px;
  }
`;

const OnboardingSection1 = styled.div`
  display: flex;
  justify-content: space-between;
  white-space: pre-wrap;
  font-size: 17px;

  & h4 {
    margin-bottom: 0;
    margin-top: 30px;
  
    @media (max-width: 1023px) {
      margin-top: 20px;
    }
  }

  & p {
    line-height: 130%;
  }


  @media (max-width: 1250px) {
    font-size: 16px;
  }

  @media (max-width: 1023px) {
    flex-direction: column;
  }

  @media (max-width: 550px) {
    font-size: 15px;
    white-space: normal;
    width: 95%;
    margin: auto;
  }

`;

const OnboardingSection2 = styled(OnboardingSection1)`
  padding-top: 6%;

  @media (max-width: 1023px) {
    padding-top: 0;
  }
`;

const Section1Column1 = styled.div`
  width: 45%;

  & img {
    width: 100%;
  }

  @media (max-width: 1023px) {
    width: 100%;

    & img {
      width: 65%;
      display: flex;
      margin: auto;
    }
  }

  @media (max-width: 550px) {
    & img {
      width: 100%;
    }
  }
`;

const Section1Column2 = styled(Section1Column1)`

`;

const Section2Column1 = styled(Section1Column1)`
  & img {

    @media (max-width: 1023px) {
      padding-top: 5%;
      padding-bottom: 5%;
    }
  }
`;

const Section2Column2 = styled(Section1Column1)`
  & img {
    padding-top: 6%;
  }
`;

export default class Onboarding extends Component {
  static propTypes = {
    pageContext: PropTypes.object,
    data: PropTypes.object,
  };

  render() {
    const { pageContext, data } = this.props;

    let canonicalLinkPrefix;

    if (pageContext.locale === 'en') {
      canonicalLinkPrefix = 'https://presono.com/en';
    } else {
      canonicalLinkPrefix = 'https://presono.com';
    }

    return (
      <Layout currentPath={pageContext.originalPath}>
        <Helmet>
          <title>{`${data?.mdx?.frontmatter?.title} | presono`}</title>
          <meta
            name="description"
            content={data?.mdx?.frontmatter?.snippetTextOnboarding}
          />

          <link rel="canonical" href={canonicalLinkPrefix + pageContext.originalPath} />

          <meta property="og:title" content={data?.mdx?.frontmatter?.title} />
          <meta property="og:description" content={data?.mdx?.frontmatter?.snippetTextOnboarding} />
          <meta property="og:image" content={`https://presono.com${data?.mdx?.frontmatter?.onboardingImageRight}`} />
          <meta name="twitter:title" content={data?.mdx?.frontmatter?.title} />
          <meta name="twitter:description" content={data?.mdx?.frontmatter?.snippetTextOnboarding} />
          <meta name="twitter:image" content={`https://presono.com${data?.mdx?.frontmatter?.onboardingImageRight}`} />
        </Helmet>
        <OnboardingContainer className="off-white-background">
          <Headline>
            {data?.mdx?.frontmatter?.onboardingHeadline}
          </Headline>
          <OnboardingHeader>
            <TextSectionHeader>
              <Subtext>
                <MDXRenderer>{data?.mdx?.frontmatter?.onboardingSubtext}</MDXRenderer>
              </Subtext>
              <HeaderText>
                <MDXRenderer>{data?.mdx?.frontmatter?.onboardingHeaderText}</MDXRenderer>
              </HeaderText>
            </TextSectionHeader>
            <HeaderImage src={data?.mdx?.frontmatter?.onboardingHeaderImage} alt={data?.mdx?.frontmatter?.altAttributeHeaderImage} />
          </OnboardingHeader>
          <OnboardingOverviewContainer>
            {data?.mdx?.frontmatter?.onboardingOverview?.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <OnboardingTopic key={index}>
                <Icon src={item?.onboardingOverviewIcon} alt={item?.altAttributeonboardingOverviewIcon} />
                <p>{item?.onboardingOverviewText}</p>
              </OnboardingTopic>
            ))}
          </OnboardingOverviewContainer>
          <OnboardingSection1>
            <Section1Column1>
              <h4>{data?.mdx?.frontmatter?.rollOutHeadline}</h4>
              <MDXRenderer>{data?.mdx?.frontmatter?.rollOutText}</MDXRenderer>
              <h4>{data?.mdx?.frontmatter?.supportHeadline}</h4>
              <MDXRenderer>{data?.mdx?.frontmatter?.supportText}</MDXRenderer>
              <h4>{data?.mdx?.frontmatter?.consultingHeadline}</h4>
              <MDXRenderer>{data?.mdx?.frontmatter?.consultingText}</MDXRenderer>
            </Section1Column1>
            <Section1Column2>
              <h4>{data?.mdx?.frontmatter?.pizzaModelHeadline}</h4>
              <MDXRenderer>{data?.mdx?.frontmatter?.pizzaModelText}</MDXRenderer>
            </Section1Column2>
          </OnboardingSection1>
          <OnboardingSection2>
            <Section2Column1>
              <h4>{data?.mdx?.frontmatter?.trainingHeadline}</h4>
              <MDXRenderer>{data?.mdx?.frontmatter?.trainingText}</MDXRenderer>
              <img src={data?.mdx?.frontmatter?.onboardingImage2} alt={data?.mdx?.frontmatter?.altAttributeonboardingImage2} />
            </Section2Column1>
            <Section2Column2>
              <h4>{data?.mdx?.frontmatter?.individualTrainingHeadline}</h4>
              <MDXRenderer>{data?.mdx?.frontmatter?.individualTrainingText}</MDXRenderer>
              <img src={data?.mdx?.frontmatter?.onboardingImage3} alt={data?.mdx?.frontmatter?.altAttributeonboardingImage3} />
            </Section2Column2>
          </OnboardingSection2>
        </OnboardingContainer>
      </Layout>
    );
  }
}

export const query = graphql`
query OnboardingQuery($locale: String!) {
  mdx(
    fields: { locale: { eq: $locale } }
    frontmatter: { layout: { eq: "onboarding"} }
  ) {
    frontmatter {
      title
      snippetTextOnboarding
      onboardingHeadline
      onboardingSubtext
      onboardingHeaderText
      onboardingHeaderImage
      altAttributeHeaderImage
      onboardingOverview {
        onboardingOverviewIcon
        altAttributeonboardingOverviewIcon
        onboardingOverviewText
      }
      rollOutHeadline
      rollOutText
      supportHeadline
      supportText
      consultingHeadline
      consultingText
      trainingHeadline
      trainingText
      pizzaModelHeadline
      pizzaModelText
      individualTrainingHeadline
      individualTrainingText
      onboardingImage2
      altAttributeOnboardingImage2
      onboardingImage3
      altAttributeOnboardingImage3
    }
  }
}
`;
